import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  useColorModes,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilBell,
  cilCaretBottom,
  cilContrast,
  cilEnvelopeOpen,
  cilList,
  cilMenu,
  cilMoon,
  cilSun,
} from "@coreui/icons";

import { AppBreadcrumb } from "./index";
import { AppHeaderDropdown } from "./header/index";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { getLocalStorageVariable, postDataToAPI } from "../../Shared/shared";
import AutoLogout from "../../components/AutoLogout";
import { fontSize } from "@mui/system";

const AppHeader = () => {
  const navigate = useNavigate();
  const headerRef = useRef();
  const { colorMode, setColorMode } = useColorModes(
    "coreui-free-react-admin-template-theme"
  );

  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const userEmail = getLocalStorageVariable("userEmail");

  useEffect(() => {
    const fetchNumber = async () => {
      const formData = new FormData();
      formData.append("email", userEmail);
      formData.append("status", "unread");

      const res = await postDataToAPI(
        "/dashboard/fetchTotalNumberOfNotifications.php",
        formData
      );

      if (res) {
        setNotificationsCount(res.count);
      }
    };
    fetchNumber();
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      headerRef.current &&
        headerRef.current.classList.toggle(
          "shadow-sm",
          document.documentElement.scrollTop > 0
        );
    });
  }, []);

  return (
    <CHeader
      position="sticky"
      className="mb-0 p-3"
      ref={headerRef}
      style={{ backgroundColor: "#001748", borderBottom: 'none' }}
    >
      <CContainer fluid className="px-0">
        <CHeaderToggler
          onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
        >
          <CIcon style={{ color: "white" }} icon={cilMenu} />
        </CHeaderToggler>
        <CHeaderNav className="d-flex" style={{ color: "white" }}>
          <CNavItem>
            <CNavLink className="px-0">
              <CContainer  fluid style={{ color: "white"}}>
                <AppBreadcrumb style={{ color: "white" }} />
              </CContainer>
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-auto">
          <CNavItem>
            <div>
              <span
                className="notification-icon"
                style={{
                  position: "relative",
                  cursor: "pointer",
                  color: "white",
                }}
                onClick={() => navigate("/notifications")}
              >
                <NotificationsIcon
                  fontSize="large"
                  style={{ marginTop: "0px", fontSize: "30px" }}
                />
                {notificationsCount > 0 && (
                  <span
                    className="badge"
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "50%",
                      padding: "0.2em 0.5em",
                      fontSize: "0.8em",
                    }}
                  >
                    {notificationsCount}
                  </span>
                )}
              </span>
            </div>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav>
          <li className="nav-item py-1">
            <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
          </li>
          <CDropdown
            variant="nav-item"
            placement="bottom-end"
          >
            <CDropdownToggle caret={false}>
              <CIcon icon={cilCaretBottom} size="lg" style={{ color: "white"}} />
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem
                active={colorMode === "light"}
                className="d-flex align-items-center"
                as="button"
                type="button"
                onClick={() => setColorMode("light")}
              >
                <CIcon className="me-2" icon={cilSun} size="lg" /> Light
              </CDropdownItem>
              {/* <CDropdownItem
                active={colorMode === 'dark'}
                className="d-flex align-items-center"
                as="button"
                type="button"
                onClick={() => setColorMode('dark')}
              >
                <CIcon className="me-2" icon={cilMoon} size="lg" /> Dark
              </CDropdownItem> */}
              {/* <CDropdownItem
                active={colorMode === "auto"}
                className="d-flex align-items-center"
                as="button"
                type="button"
                onClick={() => setColorMode("auto")}
              >
                <CIcon className="me-2" icon={cilContrast} size="lg" /> Auto
              </CDropdownItem> */}
            </CDropdownMenu>
          </CDropdown>
          <li className="nav-item py-1">
            <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
          </li>
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
