import React from 'react';
import CIcon from '@coreui/icons-react';
import { 
  cilSpeedometer,         // Dashboard Icon (still suitable for 'Home')
  cilList,                // List Icon (for 'Available Jobs')
  cilPen,                 // Pencil Icon (for 'Post Jobs')
  cilNotes,               // Notes Icon (for 'Blogs')
  cilEducation,           // Education Icon (for 'Training')
  cilSettings,            // Settings Icon (for 'Admin Panel')
} from '@coreui/icons';
import { CNavItem } from '@coreui/react';
import { getLocalStorageVariable } from '../Shared/shared';
import { adminEmail, homeOwner } from '../Shared/sharedLists';

const userEmail = getLocalStorageVariable('userEmail');
const Role = getLocalStorageVariable('role');

const _nav = [
  {
    component: CNavItem,
    name: 'Home',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Available Jobs',
    to: '/jobList',
    icon: <CIcon icon={cilList} className="nav-icon" />,
  },
  // Conditionally add 'Post Jobs' if the role is not 'homeOwner'
  ...(Role === homeOwner
    ? [
        {
          component: CNavItem,
          name: 'Post Jobs',
          to: '/postJobs',
          icon: <CIcon icon={cilPen} className="nav-icon" />,
        },
      ]
    : []),
  ...(Role !== homeOwner
    ? [
        {
          component: CNavItem,
          name: 'Applied Jobs',
          to: '/JobApplications',
          icon: <CIcon icon={cilPen} className="nav-icon" />,
        },
      ]
    : []),
  {
    component: CNavItem,
    name: 'Blogs',
    to: '/blogs',
    icon: <CIcon icon={cilNotes} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Training',
    to: '/training',
    icon: <CIcon icon={cilEducation} className="nav-icon" />,
  },
  // Conditionally add 'Admin Panel' if the email is 'adminEmail'
  ...(userEmail === adminEmail
    ? [
        {
          component: CNavItem,
          name: 'Admin Panel',
          to: '/adminDashboard',
          icon: <CIcon icon={cilSettings} className="nav-icon" />,
        },
      ]
    : []),
];

export default _nav;
