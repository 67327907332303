import React, { useState, useEffect } from "react";
import { Divider, TextField, Button } from "@mui/material";
import Swal from "sweetalert2";
import UserDataGrid from "./UserDataGrid";
import "../CSS/AdminDashboard.css";
import { getLocalStorageVariable, postDataToAPI } from "../Shared/shared";
import { Link } from "react-router-dom";
import { adminEmail } from "../Shared/sharedLists";
import AutoLogout from "../components/AutoLogout";

const AdminDashboard = () => {
  const [houseHelpAmountPayable, setHouseHelpAmountPayable] = useState(0);
  const [homeOwnersAmountPayable, setHomeOwnersAmountPayable] = useState(0);
  const [jobApplicationAmmount, setJobApplicationAmmount] = useState(0);
  const [jobPostAmmount, setJobPostAmmount] = useState(0);
  const [completeProfile, setCompleteProfile] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeSection, setActiveSection] = useState("amounts");
  const [amounts, setAmounts] = useState({
    houseHelpAmount: houseHelpAmountPayable,
    homeOwnerAmount: homeOwnersAmountPayable,
    jobApplicationAmmount: jobApplicationAmmount,
    jobPostAmmount: jobPostAmmount,
    completeProfile: completeProfile,
  });
  const [users, setUsers] = useState([]);
  const userEmail = getLocalStorageVariable("userEmail");

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await postDataToAPI(
          "/dashboard/fetchAllUsers.php",
          {}
        );
        setUsers(response || []);
      } catch (error) {
        setErrorMessage("Error fetching users. Please try again later.");
      }
    };

    const fetchAmountPayable = async () => {
      try {
        const response = await postDataToAPI(
          "/dashboard/fetchAmountPayable.php",
          {}
        );
        if (response) {
          setHouseHelpAmountPayable(response.houseHelpAmount || 0);
          setHomeOwnersAmountPayable(response.homeOwnerAmount || 0);
          setJobApplicationAmmount(response.jobApplicationAmmount || 0);
          setJobPostAmmount(response.jobPostAmmount || 0);
          setCompleteProfile(response.completeProfile || 0);
          setAmounts({
            houseHelpAmount: response.houseHelpAmount,
            homeOwnerAmount: response.homeOwnerAmount,
            jobApplicationAmmount: response.jobApplicationAmmount,
            jobPostAmmount: response.jobPostAmmount,
            completeProfile: response.completeProfile,
          });
        }
      } catch (error) {
        setErrorMessage("Error fetching amounts. Please try again later.");
      }
    };

    fetchAllUsers();
    fetchAmountPayable();
  }, []);

  const handleAmountChange = (e) => {
    const { name, value } = e.target;
    setAmounts({ ...amounts, [name]: value });
  };

  const handleSaveAmounts = async (e) => {
    e.preventDefault();
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to update the amounts payable?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, update it!",
    });

    if (result.isConfirmed) {
      const formData = new FormData();
      formData.append("houseHelpAmount", amounts.houseHelpAmount);
      formData.append("homeOwnerAmount", amounts.homeOwnerAmount);
      formData.append("jobApplicationAmmount", amounts.jobApplicationAmmount);
      formData.append("jobPostAmmount", amounts.jobPostAmmount);
      formData.append("completeProfile", amounts.completeProfile);

      const response = await postDataToAPI(
        "/dashboard/modifyPayableAmounts.php",
        formData
      );
      if (response === "Success") {
        Swal.fire("Success", "Amounts updated successfully!", "success");
      } else {
        Swal.fire("Error", "Failed to update amounts.", "error");
      }
    }
  };

  if (userEmail !== adminEmail) {
    return (
      <>
        <div className="adminDashboard">
          <h1>Access Denied!</h1>
          <p className="no-data-message">
            You do not have Access to this Interface
          </p>
        </div>
      </>
    );
  }

  return (
    <div className="dashboard-container">
    <AutoLogout />
      <div className="employer-dashboard-page">
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <div className="sectionSwitcher">
          <Link to="/viewusers">Users</Link>
          <Link to="/addBlogPost">AddBlogPost</Link>
          <Link to="/addTraining">AddTrainingResource</Link>
          <Link to="/analytics">Analytics</Link>
        </div>

        {activeSection === "amounts" && (
          <div>
            <form onSubmit={handleSaveAmounts} style={{ marginTop: 0 }}>
              <h3 className="mb-4">Modify Amounts Payable</h3>
              <TextField
                sx={{
                  marginBottom: "32px"
                }}
                label="House Help Connect Amount"
                name="houseHelpAmount"
                type="number"
                value={amounts.houseHelpAmount}
                onChange={handleAmountChange}
                required
              />
              <br />
              <TextField
                sx={{
                  marginBottom: "32px"
                }}
                label="Home Owner Connect Amount"
                name="homeOwnerAmount"
                type="number"
                value={amounts.homeOwnerAmount}
                onChange={handleAmountChange}
                required
              />
              <br />
              <TextField
                sx={{
                  marginBottom: "32px"
                }}
                label="Employees Job Application Amount"
                name="jobApplicationAmmount"
                type="number"
                value={amounts.jobApplicationAmmount}
                onChange={handleAmountChange}
                required
              />
              <br />
              <TextField
                sx={{
                  marginBottom: "32px"
                }}
                label="HomeOwners' Job Post Amount"
                name="jobPostAmmount"
                type="number"
                value={amounts.jobPostAmmount}
                onChange={handleAmountChange}
                required
              />
              <br />
              <TextField
                sx={{
                  marginBottom: "32px"
                }}
                label="Complete Profile Amount"
                name="completeProfile"
                type="number"
                value={amounts.completeProfile}
                onChange={handleAmountChange}
                required
              />
              <br />
              <Button type="submit" variant="contained" color="primary">
                Save Amounts
              </Button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
