import React, { useEffect, useMemo, useState } from "react";
import { cilMoney, cilBriefcase, cilFace, cilVideo } from "@coreui/icons";
import { CIcon } from "@coreui/icons-react/";
import { CChartBar, CChartLine, CChartPie } from "@coreui/react-chartjs";
import sampleData from "../components/sample-chart-data.json";
import sampleTableData from "../components/simple-table-data.json";
import { fontSize, styled } from "@mui/system";
import Table from "../components/Table";
import { TextField } from "@mui/material";
import { postDataToAPI } from "../Shared/shared";

const StyledInputField = styled(TextField)({
  width: "100%",
  "& label": {
    fontFamily: "inherit",
    color: "#6F7070",
  },
  "& label.Mui-focused": {
    color: "#DE1877 !important",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "inherit",
    borderRadius: "4px",
    fontSize: "14px",
    "&.Mui-focused fieldset": {
      borderColor: "#642D92",
    },
  },
});

function Analytics() {
  //States for Charts
  const [filterTimePeriod, setFilterTimePeriod] = useState("");
  const [chartData, setChartData] = useState(null);
  const [registrationRevenue, setRegistrationRevenue] = useState(null);
  const [jobRevenue, setJobRevenue] = useState(null);
  const [contactAccessRevenue, setContactAccessRevenue] = useState(null);
  const [revenueType, setRevenueType] = useState("registrationRevenue");
  const [revenueTimePeriod, setRevenueTimePeriod] = useState("dailyData");
  const [jobDataChartTimePeriod, setJobDataChartTimePeriod] =
    useState("dailyData");
  const [jobPostingActivity, setJobPostingActivity] = useState(null);
  const [employmentRate, setEmploymentRate] = useState(null);
  const [employmentRateTotals, setEmploymentRateTotals] = useState(null);
  const [jobPostingActivityChartType, setJobPostingActivityChartType] =
    useState("totalJobs");
  const [availableJobs, setAvailableJobs] = useState(null);
  const [jobSeekers, setJobSeekers] = useState(null);
  const [onboardedUsers, setOnboardedUsers] = useState(null);
  const [activeInactiveUsers, setActiveInactiveUsers] = useState(null);
  const [userTrendsTimePeriod, setUserTrendsTimePeriod] = useState("dailyData");
  const [userTrendsChartType, setUserTrendsChartType] = useState(
    "activeInactiveUsers"
  );
  const [trainingsWatched, setTrainingsWatched] = useState(null);
  const [trainingsPopularity, setTrainingsPopularity] = useState(null);
  const [trainingsCompletionRate, setTrainingsCompletionRate] = useState(null);
  const [trainingsTimePeriod, setTrainingsTimePeriod] = useState("dailyData");
  const [trainingsChartType, setTrainingsChartType] =
    useState("trainingsWatched");
  const [userActivityAndEngagement, setUserActivityAndEngagement] =
    useState(null);
  const [userDropOffRate, setUserDropOffRate] = useState(null);
  const [averageSessionTime, setAverageSessionTime] = useState(null);
  const [userEngagementTimePeriod, setUserEngagementTimePeriod] =
    useState("dailyData");
  const [userEngagementChartType, setUserEngagementChartType] =
    useState("retentionRate");
  //States for tables
  const [tableData, setTableData] = useState(null);
  const [mostActiveUsers, setMostActiveUsers] = useState(null);

  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalJobs, setTotalJobs] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalTutorials, setTotalTutorials] = useState(0);
  const [totalBlogs, setTotalBlogs] = useState(0);

  const [totalNanniesJobs, setTotalNanniesJobs] = useState(0);
  const [totalChefsJobs, setTotalChefsJobs] = useState(0);
  const [totalGardenersJobs, setTotalGardenersJobs] = useState(0);
  const [totalHousekeepersJobs, setTotalHousekeepersJobs] = useState(0);

  const [totalNanniesAvailableJobs, setTotalNanniesAvailableJobs] = useState(0);
  const [totalChefsAvailableJobs, setTotalChefsAvailableJobs] = useState(0);
  const [totalGardenersAvailableJobs, setTotalGardenersAvailableJobs] =
    useState(0);
  const [totalHouseOwnerAvailableJobs, setTotalHouseOwnerAvailableJobs] =
    useState(0);

  const [totalChefsJobSeekers, setTotalChefsJobSeekers] = useState(0);
  const [totalGardenersJobSeekers, setTotalGardenersJobSeekers] = useState(0);
  const [totalNanniesJobSeekers, setTotalNanniesJobSeekers] = useState(0);

  useEffect(() => {
    if (tableData) {
      const mostActiveUsersData = tableData.find(
        (d) => d.tableType === "mostActiveUsers"
      );
      setMostActiveUsers(mostActiveUsersData);
    }
  }, [tableData]);

  useEffect(() => {
    setFilterTimePeriod("today");
  }, []);

  useEffect(() => {
    setChartData(sampleData.charts);
    setTableData(sampleTableData.tables);
  }, []);

  const handleFilterTimePeriodChange = (e) => {
    const newValue = e.target.innerText;
    setFilterTimePeriod(newValue.toLowerCase());
  };

  //Fetch Total Revenue AS per Event
  useEffect(() => {
    const fetchTotalRevenuePerEvent = async () => {
      try {
        const formData = new FormData();
        const response = await postDataToAPI(
          "/analytics/getRevenueGenerationReport.php",
          formData
        );
        if (response) {
          const regRevenue = response.totalRevenueFromRegistration;
          const totalRevenueFromJobPosting =
            response.totalRevenueFromJobPosting;
          const totalRevenueFromJobApplication =
            response.totalRevenueFromJobApplication;
          const totalRevenueFromConnect = response.totalRevenueFromConnect;

          const revenueData = {
            dailyData: [
              {
                datetime: "TimeLine",
                registration: regRevenue,
                jobPosting: totalRevenueFromJobPosting,
                jobApplication: totalRevenueFromJobApplication,
                contactAccess: totalRevenueFromConnect,
              },
            ],
          };

          setRegistrationRevenue(revenueData);
        }
      } catch (error) {
        console.error("Error fetching Totals:", error);
      }
    };

    fetchTotalRevenuePerEvent();
  }, []);

  //Fetch Total Revenue data from Db
  useEffect(() => {
    const fetchTotalRecord = async () => {
      try {
        const formData = new FormData();
        const response = await postDataToAPI(
          "/analytics/getTotalRecords.php",
          formData
        );
        if (response) {
          setTotalRevenue(response.totalRevenue);
          setTotalJobs(response.totalJobs);
          setTotalUsers(response.totalUsers);
          setTotalTutorials(response.totalTutorials);
          setTotalBlogs(response.totalBlogs);
        }
      } catch (error) {
        console.error("Error fetching Totals:", error);
      }
    };
    fetchTotalRecord();
  }, []);

  //Fetch Total Job Posted as per Role
  useEffect(() => {
    const fetchTotalJobNumber = async () => {
      try {
        const formData = new FormData();
        const response = await postDataToAPI(
          "/analytics/getTotalJobsCount.php",
          formData
        );
        if (response) {
          setTotalHousekeepersJobs(response.homeOwner);
          setTotalChefsJobs(response.chef);
          setTotalGardenersJobs(response.gardener);
          setTotalNanniesJobs(response.houseHelp);
        }
      } catch (error) {
        console.error("Error fetching Totals:", error);
      }
    };
    fetchTotalJobNumber();
  }, [jobPostingActivityChartType]);

  //Fetch Available jobs per Role
  useEffect(() => {
    const fetchAvailableJobs = async () => {
      try {
        const formData = new FormData();
        const response = await postDataToAPI(
          "/analytics/getAvailableJobs.php",
          formData
        );
        if (response) {
          setTotalHouseOwnerAvailableJobs(response.homeOwner);
          setTotalChefsAvailableJobs(response.chef);
          setTotalGardenersAvailableJobs(response.gardener);
          setTotalNanniesAvailableJobs(response.houseHelp);
        }
      } catch (error) {
        console.error("Error fetching Totals:", error);
      }
    };
    fetchAvailableJobs();
  }, [jobPostingActivityChartType]);

  //Fetch  jobs seekers number
  useEffect(() => {
    const fetchJobSeekers = async () => {
      try {
        const formData = new FormData();
        const response = await postDataToAPI(
          "/analytics/getJobSeekers.php",
          formData
        );
        if (response) {
          setTotalChefsJobSeekers(response.chef);
          setTotalGardenersJobSeekers(response.gardener);
          setTotalNanniesJobSeekers(response.houseHelp);
        }
      } catch (error) {
        console.error("Error fetching Totals:", error);
      }
    };
    fetchJobSeekers();
  }, [jobPostingActivityChartType]);

  //Fetch Active and InActive Users
  useEffect(() => {
    const fetchActiveAndInactiveUsers = async () => {
      try {
        const formData = new FormData();
        const response = await postDataToAPI(
          "/analytics/getActiveAndInactiveUsers.php",
          formData
        );
        if (response) {
          const activeUsers = response.active;
          const inActiveUsers = response.inactive;

          const dataMade = {
            dailyData: [
              {
                datetime: "Initial Date",
                activeUsers: activeUsers,
                inactiveUsers: inActiveUsers,
              },
              {
                datetime: "Mid Date",
                activeUsers: activeUsers,
                inactiveUsers: inActiveUsers,
              },
              {
                datetime: "Current Date",
                activeUsers: activeUsers,
                inactiveUsers: inActiveUsers,
              },
            ],
          };
          setActiveInactiveUsers(dataMade);
        }
      } catch (error) {
        console.error("Error fetching Totals:", error);
      }
    };
    fetchActiveAndInactiveUsers();
  }, []);

  useEffect(() => {
    if (chartData) {
      const regRevenue = chartData.find(
        (d) => d.chartType === "registrationRevenue"
      );
      const jobRev = chartData.find((d) => d.chartType === "jobPostRevenue");
      const contactRev = chartData.find(
        (d) => d.chartType === "contactAccessRevenue"
      );
      const jobActiv = chartData.find((d) => d.chartType === "totalJobsPosted");

      const availableJobsData = chartData.find(
        (d) => d.chartType === "availableJobs"
      );
      const activeInactiveUsrs = chartData.find(
        (d) => d.chartType === "activeInactiveUsers"
      );
      const onboardedUsrs = chartData.find(
        (d) => d.chartType === "totalRegisteredUsers"
      );
      const trainingsData = chartData.find(
        (d) => d.chartType === "trainingsWatched"
      );
      const trainingsPop = chartData.find(
        (d) => d.chartType === "trainingsPopularity"
      );
      const trainingsCompletion = chartData.find(
        (d) => d.chartType === "trainingsCompletionRate"
      );
      const employmentRateData = chartData.find(
        (d) => d.chartType === "employmentRate"
      );
      const employmentRateTotalsData = chartData.find(
        (d) => d.chartType === "employmentRatetotals"
      );
      const userRetentionRateData = chartData.find(
        (d) => d.chartType === "userRetentionRate"
      );
      const dropOffRateData = chartData.find(
        (d) => d.chartType === "dropOffRate"
      );
      const averageSessionTimeData = chartData.find(
        (d) => d.chartType === "averageSessionTime"
      );
      // setRegistrationRevenue(regRevenue);
      setJobRevenue(jobRev);
      setContactAccessRevenue(contactRev);
      setJobPostingActivity(jobActiv);
      setAvailableJobs(availableJobsData);
      setJobSeekers(availableJobsData);
      // setActiveInactiveUsers(activeInactiveUsrs);
      setOnboardedUsers(onboardedUsrs);
      setTrainingsWatched(trainingsData);
      setTrainingsPopularity(trainingsPop);
      setTrainingsCompletionRate(trainingsCompletion);
      setEmploymentRate(employmentRateData);
      setEmploymentRateTotals(employmentRateTotalsData);
      setUserActivityAndEngagement(userRetentionRateData);
      setUserDropOffRate(dropOffRateData);
      setAverageSessionTime(averageSessionTimeData);
    }
  }, [chartData]);

  return (
    <div className="container-fluid">
      <div className="analytics-cards">
        <div className="analytics-card">
          <div className="icon-background">
            <CIcon icon={cilMoney} className="analytics-card-icon" />
          </div>
          <div style={{ width: "100%" }}>
            <div className="stat-and-trend-change">
              <p>KES {totalRevenue}</p>
            </div>
            <p
              style={{
                color: "#656876",
                marginTop: "16px",
                textAlign: "left",
              }}
            >
              Total Revenue
            </p>
          </div>
        </div>

        <div className="analytics-card">
          <div className="icon-background">
            <CIcon icon={cilBriefcase} className="analytics-card-icon" />
          </div>
          <div style={{ width: "100%" }}>
            <div className="stat-and-trend-change">
              <p>{totalJobs}</p>
            </div>
            <p
              style={{
                color: "#656876",
                marginTop: "16px",
                textAlign: "left",
              }}
            >
              Total Jobs
            </p>
          </div>
        </div>
        <div className="analytics-card">
          <div className="icon-background">
            <CIcon icon={cilFace} className="analytics-card-icon" />
          </div>
          <div style={{ width: "100%" }}>
            <div className="stat-and-trend-change">
              <p>{totalUsers}</p>
            </div>
            <p
              style={{
                color: "#656876",
                marginTop: "16px",
                textAlign: "left",
              }}
            >
              Total Users
            </p>
          </div>
        </div>
        <div className="analytics-card">
          <div className="icon-background">
            <CIcon icon={cilVideo} className="analytics-card-icon" />
          </div>
          <div style={{ width: "100%" }}>
            <div className="stat-and-trend-change">
              <p>{totalTutorials}</p>
            </div>
            <p
              style={{
                color: "#656876",
                marginTop: "16px",
                textAlign: "left",
              }}
            >
              Total Tutorials
            </p>
          </div>
        </div>
        <div className="analytics-card">
          <div className="icon-background">
            <CIcon icon={cilVideo} className="analytics-card-icon" />
          </div>
          <div style={{ width: "100%" }}>
            <div className="stat-and-trend-change">
              <p>{totalBlogs}</p>
            </div>
            <p
              style={{
                color: "#656876",
                marginTop: "16px",
                textAlign: "left",
              }}
            >
              Total Blogs
            </p>
          </div>
        </div>
      </div>

      <div className="dashboard-analytics-section">
        <div className="dashboard-analytics-inner-container">
          <div className="dashboard-analytics-card">
            <p className="dashboard-analytics-card-title">Revenue Per Event</p>
            <div className="chart-options">
              <div className="chart-type">
                <StyledInputField
                  select
                  id={"revenueChartTypeSelect"}
                  label={"Chart Type"}
                  slotProps={{
                    select: {
                      native: true,
                    },
                  }}
                  value={revenueType}
                  onChange={(e) => setRevenueType(e.target.value)}
                >
                  <option value={"registrationRevenue"}>
                    From Registration
                  </option>
                  <option value={"jobApplicationRevenue"}>
                    From Job Application
                  </option>
                  <option value={"jobPostRevenue"}>From Job Posting</option>
                  <option value={"contactAccessRevenue"}>
                    From Contact Access
                  </option>
                </StyledInputField>
              </div>
            </div>
            {!registrationRevenue || !registrationRevenue.dailyData ? (
              <div>Loading...</div>
            ) : (
              <CChartBar
                className="chart-area"
                data={{
                  labels: registrationRevenue.dailyData.map((d) => d.datetime),
                  datasets: [
                    {
                      label: "Registration",
                      backgroundColor: "#FF6384",
                      data: registrationRevenue.dailyData.map(
                        (d) => d.registration
                      ),
                    },
                    {
                      label: "Job Application",
                      backgroundColor: "#36A2EB",
                      data: registrationRevenue.dailyData.map((d) => d.jobApplication),
                    },
                    {
                      label: "Contact Access",
                      backgroundColor: "#4BC0C0",
                      data: registrationRevenue.dailyData.map(
                        (d) => d.contactAccess
                      ),
                    },
                    {
                      label: "Job Posting",
                      backgroundColor: "#FFCE56",
                      data: registrationRevenue.dailyData.map(
                        (d) => d.totalRevenueFromJobPosting
                      ),
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: { legend: { position: "top" } },
                }}
              />
            )}
          </div>
          <div className="dashboard-analytics-card">
            <p className="dashboard-analytics-card-title">Job Activity</p>
            <div className="chart-options">
              <div className="chart-type">
                <StyledInputField
                  select
                  id={"jobPostingChartTypeSelect"}
                  label={"Chart Type"}
                  slotProps={{
                    select: {
                      native: true,
                    },
                  }}
                  value={jobPostingActivityChartType}
                  onChange={(e) =>
                    setJobPostingActivityChartType(e.target.value)
                  }
                >
                  <option value={"totalJobs"}>Total Job Postings</option>
                  <option value={"availableJobs"}>Available Jobs</option>
                  <option value={"jobSeekers"}>Job Seekers</option>
                </StyledInputField>
              </div>
              <div className="chart-time-period">
                {/* <StyledInputField
                  select
                  id={"jobDataTimePeriodSelect"}
                  label={"Time"}
                  slotProps={{
                    select: {
                      native: true,
                    },
                  }}
                  value={jobDataChartTimePeriod}
                  onChange={(e) => setJobDataChartTimePeriod(e.target.value)}
                >
                  <option value={"dailyData"}>Daily</option>
                  <option value={"weeklyData"}>Weekly</option>
                  <option value={"monthlyData"}>Monthly</option>
                </StyledInputField> */}
              </div>
            </div>
            {jobPostingActivity &&
              jobPostingActivityChartType === "totalJobs" && (
                <CChartPie
                  className="chart-area"
                  data={{
                    labels: ["Nannies", "Chefs", "Gardeners", "Home Owners"],
                    datasets: [
                      {
                        data: [
                          totalNanniesJobs,
                          totalChefsJobs,
                          totalGardenersJobs,
                          totalHousekeepersJobs,
                        ],
                        backgroundColor: [
                          "#FF6384",
                          "#36A2EB",
                          "#FFCE56",
                          "#4BC0C0",
                        ],
                        cutout: "50%",
                      },
                    ],
                  }}
                />
              )}
            {availableJobs &&
              jobPostingActivityChartType === "availableJobs" && (
                <CChartPie
                  className="chart-area"
                  data={{
                    labels: ["Nannies", "Chefs", "Gardeners", "Housekeepers"],
                    datasets: [
                      {
                        data: [
                          totalNanniesAvailableJobs,
                          totalChefsAvailableJobs,
                          totalGardenersAvailableJobs,
                          totalHouseOwnerAvailableJobs,
                        ],
                        backgroundColor: [
                          "#FF6384",
                          "#36A2EB",
                          "#FFCE56",
                          "#4BC0C0",
                        ],
                        hoverBackgroundColor: [
                          "#FF4F7B",
                          "#2F8BE8",
                          "#F5B800",
                          "#38AFA0",
                          "#8B55F0",
                        ],
                        cutout: "50%",
                      },
                    ],
                  }}
                />
              )}
            {jobSeekers && jobPostingActivityChartType === "jobSeekers" && (
              <CChartPie
                className="chart-area"
                data={{
                  labels: ["Nannies", "Chefs", "Gardeners"],
                  datasets: [
                    {
                      data: [
                        totalNanniesJobSeekers,
                        totalChefsJobSeekers,
                        totalGardenersJobSeekers,
                      ],
                      backgroundColor: ["#FFCE56", "#4BC0C0", "#9966FF"],
                      hoverBackgroundColor: ["#F5B800", "#38AFA0", "#8B55F0"],
                    },
                  ],
                }}
              />
            )}
            {employmentRate &&
              jobPostingActivityChartType === "employmentRate" && (
                <CChartLine
                  className="chart-area"
                  data={{
                    labels: employmentRate[jobDataChartTimePeriod].map(
                      (d) => d.datetime
                    ),
                    datasets: [
                      {
                        label: "Househelp",
                        data: employmentRate[jobDataChartTimePeriod].map(
                          (d) => {
                            const jobItem = d.data.find(
                              (i) => i.jobType === "Househelp"
                            );
                            return jobItem ? jobItem.employmentPercentage : 0;
                          }
                        ),
                        borderColor: "#4dbd74",
                        fill: false,
                        tension: 0.4,
                      },
                      {
                        label: "Drivers",
                        data: employmentRate[jobDataChartTimePeriod].map(
                          (d) => {
                            const jobItem = d.data.find(
                              (i) => i.jobType === "Drivers"
                            );
                            return jobItem ? jobItem.employmentPercentage : 0;
                          }
                        ),
                        borderColor: "#20a8d8",
                        fill: false,
                        tension: 0.4,
                      },
                      {
                        label: "Nannies",
                        data: employmentRate[jobDataChartTimePeriod].map(
                          (d) => {
                            const jobItem = d.data.find(
                              (i) => i.jobType === "Nannies"
                            );
                            return jobItem ? jobItem.employmentPercentage : 0;
                          }
                        ),
                        borderColor: "#ffc107",
                        fill: false,
                        tension: 0.4,
                      },
                      {
                        label: "Caretakers",
                        data: employmentRate[jobDataChartTimePeriod].map(
                          (d) => {
                            const jobItem = d.data.find(
                              (i) => i.jobType === "Caretakers"
                            );
                            return jobItem ? jobItem.employmentPercentage : 0;
                          }
                        ),
                        borderColor: "#f86c6b",
                        fill: false,
                        tension: 0.4,
                      },
                    ],
                  }}
                />
              )}
            {employmentRateTotals &&
              jobPostingActivityChartType === "employmentRateTotals" && (
                <CChartLine
                  className="chart-area"
                  data={{
                    labels: employmentRateTotals[jobDataChartTimePeriod].map(
                      (d) => d.datetime
                    ),
                    datasets: [
                      {
                        label: "Househelp",
                        data: employmentRateTotals[jobDataChartTimePeriod].map(
                          (d) => {
                            const jobItem = d.data.find(
                              (i) => i.jobType === "Househelp"
                            );
                            return jobItem ? jobItem.total : 0;
                          }
                        ),
                        borderColor: "#4dbd74",
                        fill: false,
                        tension: 0.4,
                      },
                      {
                        label: "Drivers",
                        data: employmentRateTotals[jobDataChartTimePeriod].map(
                          (d) => {
                            const jobItem = d.data.find(
                              (i) => i.jobType === "Drivers"
                            );
                            return jobItem ? jobItem.total : 0;
                          }
                        ),
                        borderColor: "#20a8d8",
                        fill: false,
                        tension: 0.4,
                      },
                      {
                        label: "Nannies",
                        data: employmentRateTotals[jobDataChartTimePeriod].map(
                          (d) => {
                            const jobItem = d.data.find(
                              (i) => i.jobType === "Nannies"
                            );
                            return jobItem ? jobItem.total : 0;
                          }
                        ),
                        borderColor: "#ffc107",
                        fill: false,
                        tension: 0.4,
                      },
                      {
                        label: "Caretakers",
                        data: employmentRateTotals[jobDataChartTimePeriod].map(
                          (d) => {
                            const jobItem = d.data.find(
                              (i) => i.jobType === "Caretakers"
                            );
                            return jobItem ? jobItem.total : 0;
                          }
                        ),
                        borderColor: "#f86c6b",
                        fill: false,
                        tension: 0.4,
                      },
                    ],
                  }}
                />
              )}
          </div>
        </div>
        <div className="dashboard-analytics-inner-container">
          <div className="dashboard-analytics-card">
            <p className="dashboard-analytics-card-title">User Trends</p>
            <div className="chart-options">
              <div className="chart-type">
                <StyledInputField
                  select
                  id={"userTrendsChartTypeSelect"}
                  label={"Chart Type"}
                  slotProps={{
                    select: {
                      native: true,
                    },
                  }}
                  value={userTrendsChartType}
                  onChange={(e) => setUserTrendsChartType(e.target.value)}
                >
                  <option value={"activeInactiveUsers"}>
                    Active/Inactive Users
                  </option>
                </StyledInputField>
              </div>
            </div>
            {activeInactiveUsers &&
              userTrendsChartType === "activeInactiveUsers" && (
                <CChartLine
                  className="chart-area"
                  data={{
                    labels: activeInactiveUsers[userTrendsTimePeriod].map(
                      (d) => d.datetime
                    ),
                    datasets: [
                      {
                        label: "Active Users",
                        data: activeInactiveUsers[userTrendsTimePeriod].map(
                          (d) => d.activeUsers
                        ),
                        borderColor: "#36A2EB",
                        backgroundColor: "rgba(54, 162, 235, 0.2)",
                        fill: true,
                        tension: 0.4,
                      },
                      {
                        label: "Inactive Users",
                        data: activeInactiveUsers[userTrendsTimePeriod].map(
                          (d) => d.inactiveUsers
                        ),
                        borderColor: "#FF6384",
                        backgroundColor: "rgba(255, 99, 132, 0.2)",
                        fill: true,
                        tension: 0.4,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: { position: "top" },
                    },
                    scales: {
                      x: { title: { display: true, text: "Date" } },
                      y: {
                        title: { display: true, text: "No. of Users" },
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
